import { useReducer } from "react";

import APEContext from "./ape-context";

const defaultAPEState = {
  APE: {
    concessionaria: 0,
    tarifa: "",
    demanda_p: 0.0,
    demanda_fp: 0.0,
    demanda_na: 0.0,
    consumo_p: 0.0,
    consumo_fp: 0.0,
    tipo_energia: 0,
    preco: 0.0,
    potencia_solar: 0.0,
    geracao_mensal: 0.0,
    investimento: 0.0,
    email: "",
    telefone: "",
    url: "",
  },
  addConcessionaria: () => {},
  addTarifa: () => {},
  addConsumoVerde: () => {},
  addEnergia: () => {},
  addPreco: () => {},
  addPotencia: () => {},
  addUrl: () => {},
};

const APEReducer = (state, action) => {
  switch (action.type) {
    case "CONCESSIONARIA":
      return { ...state, concessionaria: action.concessionaria };
    case "TARIFA":
      return { ...state, tarifa: action.tarifa };
    case "VERDE":
      return {
        ...state,
        demanda_na: action.demanda_na,
        consumo_p: action.consumo_p,
        consumo_fp: action.consumo_fp,
      };
    case "AZUL":
      return {
        ...state,
        demanda_p: action.demanda_p,
        demanda_fp: action.demanda_fp,
        consumo_p: action.consumo_p,
        consumo_fp: action.consumo_fp,
      };
    case "ENERGIA":
      return { ...state, tipo_energia: action.tipo_energia };
    case "PRECO":
      return { ...state, preco: action.preco };
    case "POTENCIA":
      return {
        ...state,
        investimento: action.investimento,
        potencia_solar: action.potencia_solar,
        geracao_mensal: action.geracao_mensal,
      };
    case "EMAIL":
      return { ...state, email: action.email };
    case "TELEFONE":
      return { ...state, telefone: action.telefone };
    case "URL":
      return { ...state, url: action.url };
    default:
      return defaultAPEState;
  }
};

const APEProvider = (props) => {
  const [APEState, dispatch] = useReducer(APEReducer, defaultAPEState);

  const addConcessionariaHandler = (concessionaria) => {
    dispatch({ type: "CONCESSIONARIA", concessionaria: concessionaria });
  };

  const addTarifaHandler = (tarifa) => {
    dispatch({ type: "TARIFA", tarifa: tarifa });
  };

  const addConsumoVerdeHandler = (consumos) => {
    dispatch({
      type: "VERDE",
      demanda_na: consumos.demanda,
      consumo_p: consumos.consumo_p,
      consumo_fp: consumos.consumo_fp,
    });
  };

  const addConsumoAzulHandler = (consumos) => {
    dispatch({
      type: "AZUL",
      demanda_p: consumos.demanda_p,
      demanda_fp: consumos.demanda_fp,
      consumo_p: consumos.consumo_p,
      consumo_fp: consumos.consumo_fp,
    });
  };

  const addEnergiaHandler = (energia) => {
    dispatch({ type: "ENERGIA", tipo_energia: energia });
  };

  const addPrecoHandler = (preco) => {
    dispatch({ type: "PRECO", preco: preco });
  };

  const addPotenciaHandler = (consumos) => {
    dispatch({
      type: "POTENCIA",
      investimento: consumos.investimento,
      potencia_solar: consumos.potencia_solar,
      geracao_mensal: consumos.geracao_mensal,
    });
  };

  const addEmailHandler = (email) => {
    dispatch({ type: "EMAIL", email: email });
  };

  const addTelefoneHandler = (telefone) => {
    dispatch({ type: "TELEFONE", telefone: telefone });
  };

  const addUrlHandler = (url) => {
    dispatch({ type: "URL", url });
  };

  const apeFormContext = {
    APE: {
      concessionaria: APEState.concessionaria,
      tarifa: APEState.tarifa,
      demanda_p: APEState.demanda_p ?? 0,
      demanda_fp: APEState.demanda_fp ?? 0,
      demanda_na: APEState.demanda_na ?? 0,
      consumo_p: APEState.consumo_p,
      consumo_fp: APEState.consumo_fp,
      tipo_energia: APEState.tipo_energia,
      preco: APEState.preco,
      potencia_solar: APEState.potencia_solar,
      geracao_mensal: APEState.geracao_mensal,
      investimento: APEState.investimento,
      email: APEState.email,
      telefone: APEState.telefone,
      url: APEState.url,
    },
    addConcessionaria: addConcessionariaHandler,
    addTarifa: addTarifaHandler,
    addConsumoVerde: addConsumoVerdeHandler,
    addConsumoAzul: addConsumoAzulHandler,
    addEnergia: addEnergiaHandler,
    addPreco: addPrecoHandler,
    addPotencia: addPotenciaHandler,
    addEmail: addEmailHandler,
    addTelefone: addTelefoneHandler,
    addUrl: addUrlHandler,
  };

  return (
    <APEContext.Provider value={apeFormContext}>
      {props.children}
    </APEContext.Provider>
  );
};

export default APEProvider;
