import React from "react";
import classes from "./ape-Form.module.css";
import APEContext from "../store/ape-context";
import CurrencyFormat from "react-currency-format";
import emailjs from "emailjs-com";
import Select from "react-select";
import { lista } from "./concessionarias";

const initialFormState = {
  inputUm: { valor: "", isValid: true },
  inputDois: { valor: "", isValid: true },
  inputTres: { valor: "", isValid: true },
  inputQuatro: { valor: "", isValid: true },
};

// const newConcessionarias = concessionarias.sort((a, b) => a.nome.localeCompare(b.nome)).map(conc => ({
//   value: conc.id,
//   label: conc.nome
// }))

const newConcessionarias = lista.map((item) => ({
  value: item,
  label: item,
}));

function formReducer(state, action) {
  switch (action.type) {
    case "um":
      return {
        ...state,
        inputUm: { valor: action.val, isValid: action.valid },
      };
    case "dois":
      return {
        ...state,
        inputDois: { valor: action.val, isValid: action.valid },
      };
    case "tres":
      return {
        ...state,
        inputTres: { valor: action.val, isValid: action.valid },
      };
    case "quatro":
      return {
        ...state,
        inputQuatro: { valor: action.val, isValid: action.valid },
      };
    default:
      return initialFormState;
  }
}

let hasSentEmail = false;

const APEForm = ({ mobile }) => {
  const [formState, dispatch] = React.useReducer(formReducer, initialFormState);
  const [opcaoAPE, setOpcaoAPE] = React.useState("formACL");
  const [opcaoACL, setOpcaoACL] = React.useState(newConcessionarias[0]);
  const [showErrorMsg, setShowErrorMsg] = React.useState(false);
  const defaultRef = React.useRef();
  const ctx = React.useContext(APEContext);

  const aclHandler = () => {
    setOpcaoAPE("CONCESSIONARIA");
  };
  const naoHandler = () => {
    setOpcaoAPE("NAO");
  };

  const concessionariaHandler = () => {
    ctx.addConcessionaria(opcaoACL.value);
    ctx.addUrl(window.location.origin);
    setOpcaoAPE("TARIFA");
  };

  const verdeHandler = () => {
    ctx.addTarifa("VERDE");
    setOpcaoAPE("TARIFA_VERDE");
  };

  const azulHandler = () => {
    ctx.addTarifa("AZUL");
    setOpcaoAPE("TARIFA_AZUL");
  };

  const demandaHandler = () => {
    const isDemandaValid = verifyInputValidity(formState.inputUm.valor.value);
    const isConsumoPValid = verifyInputValidity(
      formState.inputDois.valor.value
    );
    const isConsumoFPValid = verifyInputValidity(
      formState.inputTres.valor.value
    );
    dispatch({
      type: "um",
      val: formState.inputUm.valor,
      valid: isDemandaValid,
    });
    dispatch({
      type: "dois",
      val: formState.inputDois.valor,
      valid: isConsumoPValid,
    });
    dispatch({
      type: "tres",
      val: formState.inputTres.valor,
      valid: isConsumoFPValid,
    });

    if (!isDemandaValid || !isConsumoPValid || !isConsumoFPValid) {
      setShowErrorMsg(true);
      return;
    }

    setShowErrorMsg(false);
    ctx.addConsumoVerde({
      demanda: formState.inputUm.valor,
      consumo_p: formState.inputDois.valor,
      consumo_fp: formState.inputTres.valor,
    });
    dispatch({ type: "reset" });
    setOpcaoAPE("ENERGIA");
  };

  const demandaFpHandler = () => {
    const isDemandaPValid = verifyInputValidity(formState.inputUm.valor.value);
    const isDemandaFPValid = verifyInputValidity(
      formState.inputDois.valor.value
    );
    const isConsumoPValid = verifyInputValidity(
      formState.inputTres.valor.value
    );
    const isConsumoFPValid = verifyInputValidity(
      formState.inputQuatro.valor.value
    );
    dispatch({
      type: "um",
      val: formState.inputUm.valor,
      valid: isDemandaPValid,
    });
    dispatch({
      type: "dois",
      val: formState.inputDois.valor,
      valid: isDemandaFPValid,
    });
    dispatch({
      type: "tres",
      val: formState.inputTres.valor,
      valid: isConsumoPValid,
    });
    dispatch({
      type: "quatro",
      val: formState.inputQuatro.valor,
      valid: isConsumoFPValid,
    });

    if (
      !isDemandaPValid ||
      !isDemandaFPValid ||
      !isConsumoPValid ||
      !isConsumoFPValid
    ) {
      setShowErrorMsg(true);
      return;
    }

    setShowErrorMsg(false);
    ctx.addConsumoAzul({
      demanda_p: formState.inputUm.valor,
      demanda_fp: formState.inputDois.valor,
      consumo_p: formState.inputTres.valor,
      consumo_fp: formState.inputQuatro.valor,
    });
    dispatch({ type: "reset" });
    setOpcaoAPE("ENERGIA");
  };

  const energiaHandler = () => {
    ctx.addEnergia(defaultRef.current.value);
    setOpcaoAPE("PRECO");
  };

  const precoHandler = () => {
    const isPrecoValid = verifyInputValidity(formState.inputUm.valor.value);
    dispatch({ type: "um", val: formState.inputUm.valor, valid: isPrecoValid });

    if (!isPrecoValid) {
      setShowErrorMsg(true);
      return;
    }

    setShowErrorMsg(false);
    ctx.addPreco(formState.inputUm.valor);
    dispatch({ type: "reset" });
    setOpcaoAPE("POTENCIA");
  };

  const potenciaHandler = () => {
    const isInvestimentoValid = verifyInputValidity(
      formState.inputUm.valor.value
    );
    const isPotenciaValid = verifyInputValidity(
      formState.inputDois.valor.value
    );
    const isGeracaoValid = verifyInputValidity(formState.inputTres.valor.value);
    dispatch({
      type: "um",
      val: formState.inputUm.valor,
      valid: isInvestimentoValid,
    });
    dispatch({
      type: "dois",
      val: formState.inputDois.valor,
      valid: isPotenciaValid,
    });
    dispatch({
      type: "tres",
      val: formState.inputTres.valor,
      valid: isGeracaoValid,
    });

    if (!isInvestimentoValid || !isPotenciaValid || !isGeracaoValid) {
      setShowErrorMsg(true);
      return;
    }

    setShowErrorMsg(false);
    ctx.addPotencia({
      investimento: formState.inputUm.valor,
      potencia_solar: formState.inputDois.valor,
      geracao_mensal: formState.inputTres.valor,
    });
    dispatch({ type: "reset" });
    setOpcaoAPE("EMAIL");
  };

  const emailHandler = () => {
    const isEmailValid = verifyInputValidity(defaultRef.current.value, "EMAIL");
    const isTelephoneValid = verifyInputValidity(
      formState.inputUm.valor.value,
      "TELEFONE"
    );
    dispatch({
      type: "um",
      val: formState.inputUm.valor,
      valid: isTelephoneValid,
    });
    defaultRef.current.className = !isEmailValid
      ? "form-control border-danger"
      : "form-control";

    if (!isTelephoneValid || !isEmailValid) {
      setShowErrorMsg(true);
      return;
    }

    setShowErrorMsg(false);
    ctx.addEmail(defaultRef.current.value);
    ctx.addTelefone(formState.inputUm.valor);
    dispatch({ type: "reset" });
    setOpcaoAPE("FINAL");
  };

  const sendEmail = () => {
    emailjs
      .send("service_2mo13lf", "template_bkgzgp7", ctx.APE, "JEZHjoZDtRK8dxgaV")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const verifyInputValidity = (input, tipo = "") => {
    if (tipo === "EMAIL") {
      var validator = require("email-validator");
      if (!validator.validate(input)) {
        return false;
      }
    } else if (tipo === "TELEFONE") {
      if (!input || input.length !== 11) return false;
      else if (input.toString().charAt(2) !== "9") {
        alert(
          "Telefone precisa ser número de telefone móvel (iniciar com digito 9)"
        );
        return false;
      }
    } else if (!input || +input === 0) {
      return false;
    }

    return true;
  };

  const checkContent = (opcao) => {
    switch (opcao) {
      case "NAO":
        return <ContentNao />;
      case "CONCESSIONARIA":
        return (
          <ContentConcessionaria
            newConcessionarias={newConcessionarias}
            setOpcaoACL={setOpcaoACL}
            concessionariaHandler={concessionariaHandler}
          />
        );
      case "TARIFA":
        return (
          <ContentTarifa
            azulHandler={azulHandler}
            verdeHandler={verdeHandler}
          />
        );
      case "TARIFA_VERDE":
        return (
          <ContentTarifaVerde
            formState={formState}
            dispatch={dispatch}
            demandaHandler={demandaHandler}
          />
        );
      case "TARIFA_AZUL":
        return (
          <ContentTarifaAzul
            formState={formState}
            dispatch={dispatch}
            demandaFpHandler={demandaFpHandler}
          />
        );
      case "ENERGIA":
        return (
          <ContentPreco
            dispatch={dispatch}
            formState={formState}
            precoHandler={precoHandler}
          />
        );
      case "PRECO":
        return (
          <ContentEnergia
            energiaHandler={energiaHandler}
            defaultRef={defaultRef}
          />
        );
      case "POTENCIA":
        return (
          <ContentPotencia
            dispatch={dispatch}
            formState={formState}
            potenciaHandler={potenciaHandler}
          />
        );
      case "EMAIL":
        return (
          <ContentEmail
            dispatch={dispatch}
            defaultRef={defaultRef}
            formState={formState}
            emailHandler={emailHandler}
          />
        );
      case "FINAL":
        if (!hasSentEmail) {
          sendEmail();
          hasSentEmail = true;
        }
        return (
          <>
            <p>
              Os seus dados serão analisados <br /> e você receberá um e-mail
              dentro de 1 dia útil <br /> com informações sobre o retorno do seu
              investimento.
            </p>
            <p>Visite o nosso site <a href="https://www.egrid.app">eGrid.</a></p>
            <p>Obrigado!</p>
          </>
        );
      default:
        return (
          <InitialContent aclHandler={aclHandler} naoHandler={naoHandler} />
        );
    }
  };

  let content = checkContent(opcaoAPE);

  let errorMsg = showErrorMsg ? (
    <div
      className="alert alert-danger"
      role="alert"
      style={mobile ? { width: "90%" } : {}}
    >
      Por favor, preencha corretamente os campos em destaque.
    </div>
  ) : (
    <></>
  );
  return (
    <>
      {errorMsg}
      <div
        className={classes.customForm}
        style={mobile ? { width: "90%" } : { minWidth: "25%" }}
      >
        {content}
      </div>
    </>
  );
};

export default APEForm;

const InitialContent = ({ aclHandler, naoHandler }) => (
  <>
    <p>A Unidade Consumidora está no Mercado Livre de Energia?</p>
    <div className="d-grid gap-2">
      <button
        onClick={aclHandler}
        type="submit"
        className="btn btn-primary btn-lg"
      >
        Sim
      </button>
      <button
        onClick={naoHandler}
        type="submit"
        className="btn btn-secondary btn-lg"
      >
        Não
      </button>
    </div>
  </>
);

const ContentNao = () => {
  const sadEmoji = (
    <h1 style={{ display: "inline", fontSize: "8rem" }}>&#128542;</h1>
  );
  return (
    <>
      {sadEmoji}
      <p>Sentimos muito.</p>
      <p>Este formulário ainda não é para você.</p>
    </>
  );
};

const ContentConcessionaria = ({
  newConcessionarias,
  setOpcaoACL,
  concessionariaHandler,
}) => {
  return (
    <>
      <p>Selecione uma concessionária:</p>
      <Select
        options={newConcessionarias}
        defaultValue={newConcessionarias[0]}
        onChange={setOpcaoACL}
      />
      <button onClick={concessionariaHandler} className="btn btn-primary">
        Próximo
      </button>
    </>
  );
};

const ContentTarifa = ({ azulHandler, verdeHandler }) => (
  <>
    <p>Qual o tipo de tarifa?</p>
    <div className="d-grid gap-2">
      <button onClick={verdeHandler} id="verde" className="btn btn-success">
        Verde
      </button>
      <button onClick={azulHandler} id="azul" className="btn btn-primary">
        Azul
      </button>
    </div>
  </>
);

const ContentTarifaVerde = ({ formState, dispatch, demandaHandler }) => (
  <>
    <div className="mb-3">
      <label className="form-label">Demanda Contratada</label>
      <CurrencyFormat
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        suffix="kW"
        type="tel"
        className={`form-control ${
          !formState.inputUm?.isValid && "border-danger"
        }`}
        onValueChange={(values) => {
          dispatch({ type: "um", val: values, valid: true });
        }}
        decimalScale={2}
      />
      <label className="form-label">Consumo Ponta</label>
      <CurrencyFormat
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        suffix="kWh/mês"
        type="tel"
        className={`form-control ${
          !formState.inputDois?.isValid && "border-danger"
        }`}
        onValueChange={(values) => {
          dispatch({ type: "dois", val: values, valid: true });
        }}
        decimalScale={2}
      />
      <label className="form-label">Consumo F. Ponta</label>
      <CurrencyFormat
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        suffix="kWh/mês"
        type="tel"
        className={`form-control ${
          !formState.inputTres?.isValid && "border-danger"
        }`}
        onValueChange={(values) => {
          dispatch({ type: "tres", val: values, valid: true });
        }}
        decimalScale={2}
      />
      <button onClick={demandaHandler} id="verde" className="btn btn-primary">
        Próximo
      </button>
    </div>
  </>
);

const ContentTarifaAzul = ({ formState, dispatch, demandaFpHandler }) => (
  <>
    <div className="mb-3">
      <label className="form-label">Demanda Ponta Contratada</label>
      <CurrencyFormat
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        suffix="kW"
        type="tel"
        className={`form-control ${
          !formState.inputUm?.isValid && "border-danger"
        }`}
        onValueChange={(values) => {
          dispatch({ type: "um", val: values, valid: true });
        }}
        decimalScale={2}
      />
      <label className="form-label">Demanda F. Ponta Contratada</label>
      <CurrencyFormat
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        suffix="kW"
        type="tel"
        className={`form-control ${
          !formState.inputDois?.isValid && "border-danger"
        }`}
        onValueChange={(values) => {
          dispatch({ type: "dois", val: values, valid: true });
        }}
        decimalScale={2}
      />
      <label className="form-label">Consumo Ponta</label>
      <CurrencyFormat
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        suffix="kWh/mês"
        type="tel"
        className={`form-control ${
          !formState.inputTres?.isValid && "border-danger"
        }`}
        onValueChange={(values) => {
          dispatch({ type: "tres", val: values, valid: true });
        }}
        decimalScale={2}
      />
      <label className="form-label">Consumo F. Ponta</label>
      <CurrencyFormat
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        suffix="kWh/mês"
        type="tel"
        className={`form-control ${
          !formState.inputQuatro?.isValid && "border-danger"
        }`}
        onValueChange={(values) => {
          dispatch({ type: "quatro", val: values, valid: true });
        }}
        decimalScale={2}
      />
      <button onClick={demandaFpHandler} id="verde" className="btn btn-primary">
        Próximo
      </button>
    </div>
  </>
);

const ContentEnergia = ({ energiaHandler, defaultRef }) => (
  <>
    <p>Selecione o tipo de energia UC:</p>
    <select
      ref={defaultRef}
      className="form-select"
      aria-label="Default select example"
    >
      <option value="CONVENCIONAL">CONVENCIONAL</option>
      <option value="i50%">INCENTIVADA i50%</option>
      <option value="i100%">INCENTIVADA i100% </option>
    </select>
    <button onClick={energiaHandler} className="btn btn-primary">
      Próximo
    </button>
  </>
);

const ContentPreco = ({ dispatch, formState, precoHandler }) => (
  <>
    <label className="form-label">
      Qual o preço da energia sem impostos? (R$/MWh)
    </label>
    <CurrencyFormat
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={false}
      prefix="R$"
      suffix="MWh"
      type="tel"
      className={`form-control ${
        !formState.inputUm.isValid && "border-danger"
      }`}
      onValueChange={(values) => {
        dispatch({ type: "um", val: values, valid: true });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
    />
    <button onClick={precoHandler} id="verde" className="btn btn-primary">
      Próximo
    </button>
  </>
);

const ContentPotencia = ({ dispatch, formState, potenciaHandler }) => (
  <div className="mb-3">
    <label className="form-label">Investimento</label>
    <CurrencyFormat
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={false}
      prefix="R$"
      type="tel"
      className={`form-control ${
        !formState.inputUm.isValid && "border-danger"
      }`}
      onValueChange={(values) => {
        dispatch({ type: "um", val: values, valid: true });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
    />
    <label className="form-label">Potência da Usina Solar</label>
    <CurrencyFormat
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={false}
      suffix="kWp"
      type="tel"
      className={`form-control ${
        !formState.inputDois.isValid && "border-danger"
      }`}
      onValueChange={(values) => {
        dispatch({ type: "dois", val: values, valid: true });
      }}
      decimalScale={2}
    />
    <label className="form-label">Geração Mensal Estimada</label>
    <CurrencyFormat
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={false}
      suffix="MWh/mês"
      type="tel"
      className={`form-control ${
        !formState.inputTres.isValid && "border-danger"
      }`}
      onValueChange={(values) => {
        dispatch({ type: "tres", val: values, valid: true });
      }}
      decimalScale={2}
    />
    <button onClick={potenciaHandler} id="verde" className="btn btn-primary">
      Próximo
    </button>
  </div>
);

const ContentEmail = ({ dispatch, defaultRef, formState, emailHandler }) => (
  <>
    <div className="form-group">
      <p className="form-label">
        Preencha os seguintes campos <br /> para obter o retorno sobre o
        investimento.
      </p>
      <input
        style={{ marginBottom: "15px" }}
        ref={defaultRef}
        type="email"
        className="form-control"
        aria-describedby="emailHelp"
        placeholder="e-mail"
      />
      <CurrencyFormat
        onValueChange={(values) => {
          dispatch({ type: "um", val: values, valid: true });
        }}
        className={`form-control ${
          !formState.inputUm.isValid && "border-danger"
        }`}
        type="tel"
        format="+55 (##) #####-####"
        mask="_"
        placeholder="telefone móvel"
      />
      <button onClick={emailHandler} type="submit" className="btn btn-success">
        Enviar
      </button>
    </div>
  </>
);
