import React from "react";

const APEContext = React.createContext({
  APE: {
    concessionaria: 1,
    tarifa: "AZUL",
    demanda_p: 0.0,
    demanda_fp: 0.0,
    demanda_na: 0.0,
    consumo_p: 0.0,
    consumo_fp: 0.0,
    tipo_energia: 1,
    preco: 0.0,
    potencia_solar: 0.0,
    geracao_mensal: 0.0,
    investimento: 0.0,
    email: "",
    telefone: "",
    url: "",
  },
  addConcessionaria: () => {},
  addTarifa: () => {},
  addConsumoVerde: () => {},
  addUrl: () => {},
});

export default APEContext;
