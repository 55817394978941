import React from "react";
import "./App.css";
import APEProvider from "./store/ape-provider";
import APEForm from "./components/ape-Form";
import { Logo } from "./components/logo/Logo";

function App() {
  const [mobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    if (window.screen.width && window.screen.width < 640) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  return (
    <APEProvider>
      <div className="App">
        <header className="App-header">
          <APEForm mobile={mobile} />
          <Logo mobile={mobile} />
        </header>
      </div>
    </APEProvider>
  );
}

export default App;
