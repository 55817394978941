import React from "react";

export const Logo = ({ mobile }) => {
  const exibirLogoNoale = window.location.href.indexOf("noale") > 0;

  return (
    <div style={{ marginTop: "10px" }}>
      {exibirLogoNoale && (
        <a
          href="https://www.noaleenergia.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/logos/noale_logo.png"
            alt="Noale logo"
            style={{ width: mobile ? "80%" : "20%" }}
          />
        </a>
      )}
      {mobile || !exibirLogoNoale ? (
        <br />
      ) : (
        <span style={{ padding: "30px" }}>|</span>
      )}
      <a href="https://www.egrid.app/" target="_blank" rel="noreferrer">
        <img
          src="/logos/egrid_logo.png"
          alt="eGrid logo"
          style={{ width: mobile ? "40%" : "15%" }}
        />
      </a>
    </div>
  );
};
