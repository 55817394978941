// const concessionarias = [
//   {
//       "id": 3,
//       "nome": "Rio Grande Energia S.A."
//   },
//   {
//       "id": 5,
//       "nome": "ENEL DISTRIBUIÇÃO CEARÁ"
//   },
//   {
//       "id": 10,
//       "nome": "Companhia de Eletricidade do Estado da Bahia"
//   },
//   {
//       "id": 38,
//       "nome": "ENEL no Brasil S.A."
//   },
//   {
//       "id": 44,
//       "nome": "Companhia Paulista de Força e Luz"
//   },
//   {
//       "id": 52,
//       "nome": "EDP Espírito Santo Centrais Elétricas S.A."
//   },
//   {
//       "id": 59,
//       "nome": "Elektro Eletricidade e Serviços S/A."
//   },
//   {
//       "id": 35,
//       "nome": "Energisa Sergipe - Distribuidora de Energia S.A."
//   },
//   {
//       "id": 42,
//       "nome": "ENEL GO"
//   },
//   {
//       "id": 65,
//       "nome": "CELPA"
//   },
//   {
//       "id": 11,
//       "nome": "Cooperativa Regional de Energia e Desenvolvimento Ijuí LTDA"
//   },
//   {
//       "id": 31,
//       "nome": "ELETROCAR - CENTRAIS ELÉTRICAS DE CARAZINHO"
//   },
//   {
//       "id": 37,
//       "nome": "CEB Distribuição S/A"
//   },
//   {
//       "id": 43,
//       "nome": "Companhia Luz e Força Mococa"
//   },
//   {
//       "id": 64,
//       "nome": "ENERGISA SUL-SUDESTE"
//   },
//   {
//       "id": 66,
//       "nome": "ENERGISA TOCANTINS"
//   },
//   {
//       "id": 67,
//       "nome": "Energisa Mato Grosso - Distribuidora de Energia S.A."
//   },
//   {
//       "id": 70,
//       "nome": "Companhia Luz e Força Santa Cruz"
//   },
//   {
//       "id": 29,
//       "nome": "EDP São Paulo Distribuição de Energia S.A."
//   },
//   {
//       "id": 51,
//       "nome": "Companhia Piratininga de Força e Luz"
//   },
//   {
//       "id": 91,
//       "nome": "CEEE-D Rural "
//   },
//   {
//       "id": 93,
//       "nome": "Cooperativa de Eletricidade de São Ludgero "
//   },
//   {
//       "id": 94,
//       "nome": "CEEE-D LIVRE"
//   },
//   {
//       "id": 95,
//       "nome": "Departamento Municipal de Energia de Ijuí"
//   },
//   {
//       "id": 92,
//       "nome": "Cooperativa de Eletrificação Anita Garibaldi "
//   },
//   {
//       "id": 96,
//       "nome": "Companhia Energética de Pernambuco"
//   },
//   {
//       "id": 97,
//       "nome": "ENERGISA RIO DE JANEIRO "
//   },
//   {
//       "id": 98,
//       "nome": "Energisa Paraíba Distribuidora de Energia S/A"
//   },
//   {
//       "id": 99,
//       "nome": "Empresa Luz e Força Santa Maria S/A"
//   },
//   {
//       "id": 100,
//       "nome": "Amazonas Distribuidora de Energia S/A"
//   },
//   {
//       "id": 90,
//       "nome": "Copel Distribuição S/A"
//   },
//   {
//       "id": 4,
//       "nome": "Celesc Distribuição S.A."
//   },
//   {
//       "id": 101,
//       "nome": "COOPERATIVA ALIANÇA"
//   },
//   {
//       "id": 102,
//       "nome": "Companhia Energética do Rio Grande do Norte"
//   },
//   {
//       "id": 1,
//       "nome": "Companhia Estadual de Distribuição de Energia Elétrica"
//   },
//   {
//       "id": 30,
//       "nome": "Light Serviços de Eletricidade S.A."
//   },
//   {
//       "id": 103,
//       "nome": "CEMIG Distribuição S/A"
//   },
//   {
//       "id": 104,
//       "nome": "Energisa Nova Friburgo - Distribuidora de Energia S.A."
//   },
//   {
//       "id": 105,
//       "nome": "ELETROPAULO METROPOLITANA ELETRICIDADE DE SÃO PAULO S. A"
//   },
//   {
//       "id": 2,
//       "nome": "RGE SUL Distribuidora de Energia S.A."
//   },
//   {
//       "id": 106,
//       "nome": "Companhia Sul Sergipana de Eletricidade"
//   }
// ]

export const lista = [
  "AME",
  "Boa Vista",
  "CAIUÃ",
  "CASTRO - DIS",
  "CEA",
  "CEB",
  "CEBDIS",
  "CEDRAP",
  "CEDRI",
  "CEEE-D",
  "CEGERO",
  "CEJAMA",
  "CELESC",
  "CELESC-DIS",
  "CELETRO",
  "CELG-D",
  "CELPE",
  "CEMIG-D",
  "CEMIRIM",
  "CEPRAG",
  "CERAÇÁ",
  "CERAL ANITÁPOLIS",
  "CERAL ARAPOTI",
  "CERAL ARARUAMA",
  "CERBRANORTE",
  "CERCI",
  "CERCOS",
  "CEREJ",
  "CERES",
  "CERFOX",
  "CERGAL",
  "CERGAPA",
  "CERGRAL",
  "CERILUZ",
  "CERIM",
  "CERIPA",
  "CERIS",
  "CERMC",
  "CERMISSÕES",
  "CERMOFUL",
  "CERNHE",
  "CERON",
  "CERPALO",
  "CERPRO",
  "CERR",
  "CERRP",
  "CERSAD DISTRIBUIDORA",
  "CERSUL",
  "CERTAJA",
  "CERTEL ENERGIA",
  "CERTHIL",
  "CERTREL",
  "CERVAM",
  "CETRIL",
  "CFLO",
  "CHESP",
  "CNEE",
  "COCEL",
  "CODESAM",
  "COELBA",
  "COOPERA",
  "COOPERALIANÇA",
  "COOPERCOCAL",
  "COOPERLUZ",
  "COOPERMILA",
  "COOPERNORTE",
  "COOPERSUL",
  "COOPERZEM",
  "COORSEL",
  "COPEL-DIS",
  "COPREL",
  "COSERN",
  "CPFL Jaguari",
  "CPFL Leste Paulista",
  "CPFL Mococa",
  "CPFL- PIRATININGA",
  "CPFL Santa Cruz",
  "CPFL Sul Paulista",
  "CPFL-PAULISTA",
  "CRELUZ-D",
  "CRERAL",
  "DCELT",
  "DEMEI",
  "DMED",
  "EBO",
  "EDEVP",
  "EDP ES",
  "EDP SP",
  "EEB",
  "EFLJC",
  "EFLUL",
  "ELEKTRO",
  "ELETROACRE",
  "ELETROCAR",
  "ELETROPAULO",
  "ELFSM",
  "EMG",
  "EMS",
  "EMT",
  "ENEL CE",
  "ENEL RJ",
  "ENF",
  "EPB",
  "Equatorial AL",
  "Equatorial MA",
  "Equatorial PA",
  "Equatorial PI",
  "ESE",
  "ESS",
  "ETO",
  "FORCEL",
  "HIDROPAN",
  "LIGHT",
  "MUXENERGIA",
  "RGE",
  "RGE SUL",
  "SULGIPE",
  "UHENPAL",
];
